import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout.vue";

import NotFound from "@/views/NotFoundPage.vue";

const routes = [
  {
    path: "/",
    redirect: "dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          allow: "all",
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("../views/Dashboard.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        meta: {
          allow: "all",
        },
        component: () => import("../views/Pages/UserProfile.vue"),
      },
      {
        path: "/clients",
        name: "Clients",
        meta: {
          allow: "admin",
        },
        component: () => import("../views/Clients.vue"),
      },

      {
        path: "/thrive-data",
        name: "Thrive Data",
        meta: {
          allow: "admin",
        },
        component: () => import("../views/ThriveData.vue"),
      },
      {
        path: "/thrive-offers",
        name: "Thrive Offers",
        meta: {
          allow: "admin",
        },
        component: () => import("../views/ThriveOffers.vue"),
      },
      {
        path: "/thrive-traders",
        name: "Thrive Traders",
        meta: {
          allow: "admin",
        },
        component: () => import("../views/ThriveTraders.vue"),
      },
      {
        path: "/thrive-mappings",
        name: "Thrive Mappings",
        meta: {
          allow: "admin",
        },
        component: () => import("../views/ThriveMappings.vue"),
      },
      {
        path: "/thrive-payments",
        name: "Thrive Payments",
        meta: {
          allow: "admin",
        },
        component: () => import("../views/ThrivePayments.vue"),
      },
      {
        path: "/thrive-disbursements",
        name: "Thrive Disbursements",
        meta: {
          allow: "admin",
        },
        component: () => import("../views/ThriveDisbursements.vue"),
      },
      {
        path: "/referrals",
        name: "Referrals",
        meta: {
          allow: "all",
        },
        component: () => import("../views/leads.vue"),
      },
      {
        path: "/suppliers",
        name: "Distributors",
        meta: {
          allow: "admin",
        },
        component: () => import("../views/Suppliers.vue"),
      },
      {
        path: "/disbursements",
        name: "disbursements",
        meta: {
          allow: "all",
        },
        component: () => import("../views/Disbursements.vue"),
      },
      {
        path: "/devices",
        name: "devices",
        meta: {
          allow: "admin",
        },
        component: () => import("../views/Devices.vue"),
      },
      {
        path: "/users",
        name: "users",
        meta: {
          allow: "admin",
        },
        component: () => import("../views/Users.vue"),
      },
      {
        path: "/transactions",
        name: "transactions",
        meta: {
          allow: "all",
        },
        component: () => import("../views/Transactions.vue"),
      },
      {
        path: "/summary-reports",
        name: "summary-reports",
        meta: {
          allow: "all"
        },
        component: () => import("../views/SummaryReport.vue")
      },
      {
        path: "/payat-reports",
        name: "pay@ reports",
        meta: {
          allow: "all"
        },
        component: () => import("../views/PayAtReport.vue")
      },
      {
        path: "/icons",
        name: "icons",
        meta: {
          allow: "all",
        },
        component: () => import("../views/Icons.vue"),
      },
      {
        path: "/maps",
        name: "maps",
        meta: {
          allow: "all",
        },
        component: () => import("../views/GoogleMaps.vue"),
      },
      {
        path: "/tables",
        name: "tables",
        meta: {
          allow: "all",
        },
        component: () => import("../views/RegularTables.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    allow: "all",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Account/Login.vue"),
        meta: {
          noAuthRequired: true,
        },
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Account/PasswordResetConfirm.vue"),
        meta: {
          noAuthRequired: true,
        },
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () =>
          import(/* webpackChunkName: "demo" */ "../views/Account/ForgotPassword.vue"),
        meta: {
          noAuthRequired: true,
        },
      },
      { path: "*", component: NotFound },
    ],
  },
];

export default routes;
