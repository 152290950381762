import axios from 'axios'
import config from '../config'

const state = {
  channels: []
}

const actions = {
    addChannels({ commit, rootState }) {
        const { token } = rootState.accounts;
        // Get branches from 
        var http = axios.create({
            baseURL: config.apiUrl,
            timeout: 30000,
            headers: { 'Authorization': token }
        });
        
        http.get("/transaction/channels").then(response => {
          const channels = response.data;
          commit('add', channels);
        }).catch(err => console.log(err));
        // Commit add mutations to store in state
    }
}

const mutations = {
    add(state, payload) {
    state.channels = payload
    }
}

const getters = {
  channels: state => state.channels
}

export const channels = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
