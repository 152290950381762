import axios from "axios";
import config, { apiUrl } from "../config";

const state = {
  isLoading: false,
  success: null,
  error: null,
  traders: [],
  cards: []
};

const actions = {
  addTraders({ commit }, traders) {
    commit("ADD_TRADERS", traders);
  },
};

const mutations = {
  ADD_TRADERS(state, traders) {
    state.traders = traders;
  }
};

const getters = {
  traders: state => state.traders,
  loading: state => state.isLoading,
  success: state => state.success
};

export const thriveData = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
