import axios from "axios";
import config, { apiUrl } from "../config";

const state = {
  distributors: [],
  isLoading: false,
  success: null,
  error: null,
};

const actions = {
  getSuppliers({ commit, rootState }) {
    // Start loading
    commit("startLoad");
    const { token } = rootState.accounts;
    // Get branches from
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token },
    });

    http
      .get("/supplier/")
      .then((response) => {
        const suppliers = response.data;
        commit("add", suppliers);
        commit("finishedLoad");
        return suppliers;
      })
      .catch((err) => console.log(err));
  },
  async getAllSuppliers({ commit, rootState }) {
    // Get token from state
    const { token } = rootState.accounts;
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    // Proxy settings
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" },
    });

    // Send Request
    const result = await http.get(`${apiUrl}/supplier`);

    if (result && result.statusText == "OK") {
      // Add the returns suppliers to the state
      commit("distributors", result.data);
      // Return value for components calling this action
      return result.data;
    }
  },
  async addNewSupplier({ commit, rootState }, supplier) {
    // Start request
    commit("startRequest");
    // Get token from state
    const { token } = rootState.accounts;
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    // Proxy settings
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" },
    });

    // Send Request
    const result = await http.post(`${apiUrl}/supplier`, supplier, config);

    // Commit mutation
    if (result.data && result.data == "OK") {
      commit("requestSuccess");
    } else {
      commit("requestFailed");
    }
    // Return result
    return result;
  },
  async disableUser({ commit, rootState }, id) {
    // Get token from state
    const { token } = rootState.accounts;
    // Request configuation
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token },
    });
    // Send Request
    const result = await http.post(
      `${apiUrl}/supplier/disable`,
      {
        supplierId: id,
      },
      config
    );

    // Return result
    return result;
  },
  userAddedComplete({ commit }) {
    commit("complete");
  },
};

const mutations = {
  startRequest(state) {
    state.isLoading = true;
  },
  requestSuccess(state) {
    state.isLoading = false;
    state.success = true;
    state.error = null;
  },
  requestFailed(state, payload) {
    state.isLoading = false;
    state.success = null;
    state.error = "There was an error(s) with your request.";
  },
  complete(state) {
    state.isLoading = false;
    state.success = null;
    state.error = null;
  },
  add(state, distributors) {
    state.distributors = distributors;
  },
  startLoad(state) {
    state.isLoading = true;
  },
  finishedLoad(state) {
    state.isLoading = false;
  },
  startRequest(state) {
    state.isLoading = true;
  },
  requestSuccess(state) {
    state.isLoading = false;
    state.success = true;
    state.error = null;
  },
  requestFailed(state, payload) {
    state.isLoading = false;
    state.success = null;
    state.error = "There was an error(s) with you request.";
  },
  distributors(state, suppliers) {
    state.distributors = suppliers;
  },
};

const getters = {
  distributors: (state) => state.distributors,
  isLoading: (state) => state.isLoading,
  success: (state) => state.success,
};

export const suppliers = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
