import config, { apiUrl } from "../config";
import axios from "axios";
import { suppliers } from "./suppliers.module";
/*
 *  Initial state
 */

const actions = {
  // Add device to user
  async addDeviceToUser({ commit, rootState, rootGetters }, data) {
    // Start request
    commit("startRequest");
    // Get token from state
    const { token } = rootState.accounts;
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };

    // Proxy settings
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" }
    });

    // Send Request
    const result = await http.post(`${apiUrl}/device/register`, data, config);

    // Commit mutation
    if (result.data && result.data == "OK") {
      commit("requestSuccess");
    } else {
      commit("requestFailed");
    }

    // Return result
    return result;
  },
  async getUserDevices({ commit, rootState }, Id) {
    // Start request
    commit("startRequest");
    // Get token from state
    const { token } = rootState.accounts;
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };

    // Proxy settings
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" }
    });

    // Send Request
    const result = await http.get(`${apiUrl}/supplier/devices/${Id}`, config);

    if (result && result.data) {
      // Commit mutation
      console.log('devices', result.data);
      commit("getUserDevices", result.data);
      // // End request
      commit("requestSuccess");
    } else {
      
    }

    // Return result
    return result;
  },
  // Unlink device from user
  async unlinkDeviceFromUser({ commit, rootGetters, rootState }, device) {
    // Get device id to update UI, serialNumber & supplierId
    const { Id, serialNumber, supplierId } = device;
    // Get token from state
    const { token } = rootState.accounts;
    // Config
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      }
    };

    // Proxy settings
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" }
    });

    // Send Request
    const result = await http.post(`${apiUrl}/device/remove`, device, config);


    if (result && result.statusText == "OK") {
      // Commit mutation
      commit("removeDevice", Id);
      // End request
      //commit("requestSuccess");
    } else {
      commit("requestFailed");
    }

    return result;
  },
  complete({ commit }) {
    commit("complete");
  }
};

const mutations = {
  startRequest(state) {
    state.isLoading = true;
  },
  requestSuccess(state) {
    state.isLoading = false;
    state.success = true;
    state.error = null;
  },
  requestFailed(state, payload) {
    state.isLoading = false;
    state.success = null;
    state.error = "There was an error(s) with your request.";
  },
  complete(state) {
    state.isLoading = false;
    state.success = null;
    state.error = null;
  },
  removeDevice(state, id) {
    // Get all devices in store
    const devices = JSON.parse(JSON.stringify(state.devices));
    // Filter out unlinked device
    const filteredDevices = devices.filter(d => d.Id.toString() != id);
    // update state with left over devices
    state.devices = filteredDevices;
  },
  getUserDevices(state, devices) {
    state.devices = devices;
  }
};

const getters = {
  success: state => state.success,
  error: state => state.error,
  isLoading: state => state.isLoading,
  devices: state => state.devices
};

export const devices = {
  namespaced: true,
  state() {
    return {
      isLoading: false,
      success: null,
      error: null,
      devices: []
    };
  },
  actions,
  mutations,
  getters
};
