import axios from 'axios'
import config from '../config'

const state = {
    branches: []
}

const actions = {
    addBranches({ commit, rootState }) {
        const { token } = rootState.accounts;
        // Get branches from 
        var http = axios.create({
            baseURL: config.apiUrl,
            timeout: 30000,
            headers: { 'Authorization': token }
        });
        
        http.get("/client/branches").then(response => {
            const branches = response.data;
            commit('add', branches);
        }).catch(err => console.log(err));
        // Commit add mutations to store in state
    }
}

const mutations = {
    add(state, payload) {
        state.branches = payload
    }
}

const getters = {
    branches: state => state.branches
}

export const branches = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
