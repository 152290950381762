import { userService } from "../services";
import axios from "axios";
import config, { apiUrl } from "../config";

const state = {
  authenticated: {},
  roles: [],
  users: [],
  isLoading: false,
  success: null,
  error: null
};

const actions = {
  getAll({ commit }) {
    commit("getAllRequest");

    userService.getAll().then(
      users => commit("getAllSuccess", users),
      error => commit("getAllFailure", error)
    );
  },
  async deleterUser({ commit, rootState }, id) {
    // Get authenticated user token
    const { token } = rootState.accounts;
    // Get branches froms
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" }
    });

    const result = await http.delete(`${apiUrl}/user/${id}`);
  },
  async getAllUsers({ commit, rootState }) {
    // Get authenticated user token
    const { token } = rootState.accounts;
    // Get branches froms
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" }
    });

    const result = await http.get(`${apiUrl}/user`);


    if (result && result.statusText == "OK") {
      // Update state with updated user
      commit("updateUsers", result.data);
      // Return latest users
      return result.data;
    }

    
  },
  async addUser({ commit, rootState }, user) {
    // Start request
    commit("startRequest");
    // Get authenticated user token
    const { token } = rootState.accounts;
    // Get branches froms
    var http = axios.create({
      baseURL: config.apiUrl,
      timeout: 30000,
      headers: { Authorization: token, "Content-Type": "application/json" }
    });

    try {
      const result = await http.post(`${apiUrl}/user`, user);

    if (result && result.data == "OK") {
      commit("requestSuccess");
    } else {
      
    }

    return result;
    } catch (error) {
      console.log(error);
      commit("requestFailed");
    }

    
  },
  update({ commit }, user) {
    userService.update(user).then(
      user => commit("updateSuccess", user),
      error => commit("updateFailure", { user, error: error.toString() })
    );
  },
  delete({ commit }, id) {
    commit("deleteRequest", id);

    userService.delete(id).then(
      user => commit("deleteSuccess", id),
      error => commit("deleteFailure", { id, error: error.toString() })
    );
  },
  updateUsers({ commit }, users) {
    commit("updateUsers", users);
  },
  complete({ commit }) {
    commit("complete");
  }
};

const mutations = {
  startRequest(state) {
    state.isLoading = true;
  },
  requestSuccess(state) {
    state.isLoading = false;
    state.success = true;
    state.error = null;
  },
  requestFailed(state, payload) {
    state.isLoading = false;
    state.success = null;
    state.error = "There was an error(s) with your request.";
  },
  complete(state) {
    state.isLoading = false;
    state.success = null;
    state.error = null;
  },
  getAllRequest(state) {
    state.all = { loading: true };
  },
  getAllSuccess(state, users) {
    state.all = { items: users, loading: false };
  },
  getAllFailure(state, error) {
    state.all = { error };
  },
  deleteRequest(state, id) {
    // add 'deleting:true' property to user being deleted
    state.all.items = state.all.items.map(user =>
      user.id === id ? { ...user, deleting: true } : user
    );
  },
  addSuccess(state, user) {},
  addFailue(state, { user, error }) {},
  updateSuccess(state, user) {},
  updateFailue(state, { user, error }) {},
  deleteSuccess(state, id) {
    // remove deleted user from state
    state.all.items = state.all.items.filter(user => user.id !== id);
  },
  deleteFailure(state, { id, error }) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    state.all.items = state.items.map(user => {
      if (user.id === id) {
        // make copy of user without 'deleting:true' property
        const { deleting, ...userCopy } = user;
        // return copy of user with 'deleteError:[error]' property
        return { ...userCopy, deleteError: error };
      }

      return user;
    });
  },
  updateUsers(state, users) {
    state.users = users;
  },
  addUser(state, users) {
    state.user = users;
  }
};

const getters = {
  isLoggedIn: state => !!state.authenticated,
  users: state => state.users,
  loading: state => state.isLoading,
  success: state => state.success,
  error: state => state.error
};

export const users = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
