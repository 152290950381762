/*!

=========================================================
* BootstrapVue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import store from "./store";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";
import router from "./routes/router";
import config from "./config.js";
import axios from "axios";
import VueAxios from "vue-axios";
import VueLuxon from "vue-luxon";
import remotedev from "vuex-remotedev";
import { ValidationProvider } from 'vee-validate';
remotedev(store);

Vue.use(VueLuxon, {
  input: {
    zone: config.TimeZone,
    format: "iso"
  }
});
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);
Vue.component('ValidationProvider', ValidationProvider);
Vue.prototype.$store = store;

const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = token;
}

Vue.config.productionTip = false;

/* Default route gaurd only allowing unathenticated access to the login page.*/
router.beforeEach((to, from, next) => {
  if (to.matched.some(x => x.meta && x.meta.noAuthRequired)) {
    next();
  } else {
    //var isSupplier = store.getters["accounts/supplierId"] != "";
    if (store.getters["accounts/isLoggedIn"]) {
      next();
      return;
    }
    next("/login");
  }
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
